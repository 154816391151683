import Head from "next/head";
import siteInfo from "@/constants/siteInfo";
import defaultMetaTags from "@/constants/defaultMetaTags";

const HeadTag = ({
  title = "",
  metaTitle = "",
  description = "",
  keywords = "",
  image = "",
  canonicalUrl = "",
  ampUrl = "",
  robotFollow = true,
  showMaxImagePreview = false,
  lcpImage = "",
  articlePublishedTime = "",
  articleModifiedTime = "",
  isAmp = false,
  noAuthorTag = true,
  authorName = "",
}) => {
  // let blockRobotsFollowTag = process.env.NEXT_PUBLIC_BLOCK_ROBOTSTAG;
  return (
    <Head>
      <title>{metaTitle || title || defaultMetaTags?.title}</title>
      <meta
        name="description"
        content={description || defaultMetaTags?.description}
      />
      <meta
        name="robots"
        content={!robotFollow ? "noindex, nofollow" : "MAX-IMAGE-PREVIEW:LARGE"}
      />
      <meta property="og:type" content="website" />
      {keywords && (
        <meta name="keywords" content={keywords || defaultMetaTags?.keywords} />
      )}
      <meta
        name="google-site-verification"
        content={siteInfo?.googleSiteVerification}
      />
      {siteInfo.sitemapPath && (
        <link
          rel="sitemap"
          type="application/xml"
          title="Sitemap"
          href={siteInfo.sitemapPath}
        />
      )}
      {ampUrl && <link rel="amphtml" href={ampUrl} />}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      <meta property="fb:pages" content={siteInfo?.facebookAppId} />
      <meta property="og:site_name" content={siteInfo?.siteName} />
      <meta property="og:title" content={title || defaultMetaTags?.title} />
      <meta
        property="og:description"
        content={description || defaultMetaTags?.description}
      />
      {canonicalUrl && <meta property="og:url" content={canonicalUrl} />}
      {image && <meta property="og:image" content={image} />}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title || defaultMetaTags?.title} />
      {image && <meta name="twitter:image" content={image} />}
      <meta name="twitter:domain" content={siteInfo?.siteDomain} />
      {canonicalUrl && <meta name="twitter:url" content={canonicalUrl} />}
      <meta name="twitter:site_name" content={siteInfo?.siteName} />
      <meta
        name="twitter:description"
        content={description || defaultMetaTags?.description}
      />
      <meta name="twitter:site" content={siteInfo?.twitterSiteId} />
      <meta name="twitter:creator" content={siteInfo?.twitterCreatorId} />
      {noAuthorTag && <meta name="author" content={siteInfo?.siteAuthor} />}
      {authorName && <meta name="author" content={authorName} />}
      {articlePublishedTime && (
        <meta
          property="article:published_time"
          content={articlePublishedTime}
        />
      )}
      {articleModifiedTime && (
        <meta property="article:modified_time" content={articleModifiedTime} />
      )}
      {lcpImage && !isAmp && (
        <link
          rel="preload"
          fetchpriority="high"
          as="image"
          href={lcpImage}
          type="image/webp"
        />
      )}
    </Head>
  );
};

export default HeadTag;
